function updateRoomvaluesFields(element) {
    $.ajax({
        type: 'GET',
        url: '/rooms/' + element.val() +  '/fetch',
        dataType: 'json',
        beforeSend: function () {
            $('#js_dialog_form').html(alertLoading());
            $('#js_dialog_form').dialog({
                title: "",
                width: 200,
                modal: true,
            })
            $(".ui-dialog-titlebar").hide();
        },
        success: function (response) {
            if (response.fetched) {
                $('select[name=\'room_id\']').replaceWith(response.html);
            } else {
                $('select[name=\'room_id\']').replaceWith('<div class="alert alert-danger">Error.</div>');
            }
        }
    });
}

function updateUndertakervaluesFields(element) {
    $.ajax({
        type: 'GET',
        url: '/undertakers/' + element.val() +  '/fetch',
        dataType: 'json',
        beforeSend: function () {
            $('#js_dialog_form').html(alertLoading());
            $('#js_dialog_form').dialog({
                title: "",
                width: 200,
                modal: true,
            })
            $(".ui-dialog-titlebar").hide();
        },
        success: function (response) {
            if (response.fetched) {
                $('select[name=\'undertaker_id\']').replaceWith(response.html);
            } else {
                $('select[name=\'undertaker_id\']').replaceWith('<div class="alert alert-danger">Error.</div>');
            }
        }
    });
}

function updateRelatedCeremonyFields(element) {
    var selectedConsultantValue = element.val();
    if (selectedConsultantValue > 0) {
        $('select[name=\'writer_id\']').val(selectedConsultantValue);
        $('select[name=\'speaker_id\']').val(selectedConsultantValue);
    }
}

function showHidePartnerFields() {
    var selectedSituationValue = $('select[name=\'situation\']').val();
    if (selectedSituationValue == 'partner' || selectedSituationValue == 'echtgeno(o)t(e)') {
        $('.partner-field').show();
    } else {
        $('.partner-field').hide();
        $('.partner-field').find('input').val('');
    }
}

$(function () {

    $('select[name=\'region_id\']').on('change', function () {
        updateRoomvaluesFields($(this));
        updateUndertakervaluesFields($(this));
    })

    $('select[name=\'consultant_id\']').on('change', function () {
        updateRelatedCeremonyFields($(this));
    })

    showHidePartnerFields();
    $('select[name=\'situation\']').on('change', function () {
        showHidePartnerFields();
    })
});
