$(document).ready(function() {
    fadeOutAndRemoveSuccessAlertMessage()
})

function fadeOutAndRemoveSuccessAlertMessage() {
    if ($('.js_alert_message_success:not(.alert-fix)').length == 1) {
        $('.js_alert_message_success:not(.alert-fix)').first().fadeOut(4000, function() { $(this).remove(); })
    }
}

function alertLoading() {
    return '<p><i class="fas fa-spinner fa-spin"></i> <strong>Gegevens laden...</strong></p>';
}

function alertProcessing() {
    return '<div class="alert alert-warning"><p><i class="fa fa-refresh"></i> <strong>Gegevens verwerken...</strong></p></div>';
}

function alertDataSaved() {
    return '<div class="alert alert-success"><p><i class="fa fa-check"></i> Gegevens opgeslagen!</p></div>';
}

function alertDataMailed() {
    return '<div class="alert alert-success"><p><i class="fa fa-check"></i> Mail verzonden!</p></div>';
}

function alertReloading() {
    return '<p><i class="fa fa-refresh"></i> <strong>De pagina wordt automatisch vernieuwd...</strong></p>';
}
