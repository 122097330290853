$(document).ready(function () {
    function addOptionvalue() {
        var optionvalue = $('#js_optionvalue').val();
        if (typeof optionvalue !== "undefined" && optionvalue != "") {
            var listgroupitem = '<li class="list-group-item"><button class="btn btn-link btn-sm js_remove_optionvalue"><i class="fas fa-trash-alt"></i></button>' + optionvalue + '<input type="hidden" name="optionvalues[]" value="' + optionvalue + '"></li>';
            $('ul.list-group').first().append(listgroupitem);
            $('#js_optionvalue').val("");
        }
    }

    function removeOptionvalue(element) {
        element.parent().remove();
    }

    $('body').on('click', '#js_add_optionvalue', function (e) {
        e.preventDefault();
        addOptionvalue();
    })

    $(window).on('keydown', function (e) {
        if ($('#js_optionvalue').is(':focus') && e.key == 'Enter') {
            e.preventDefault();
            addOptionvalue();
            return false;
        }
    })

    $('body').on('click', '.js_remove_optionvalue', function (e) {
        e.preventDefault();
        removeOptionvalue($(this));
    })
})
