var dialogHtml;

$(document).ready(function () {
    $('body').on('click', '.js_load_modal', function () {
        loadModal($(this));
        return false;
    })
    $('body').on('click', '.js_load_modal_download', function () {
        loadModalDownload($(this));
        return false;
    })
    $('body').on('click', '.js_load_confirmation_modal', function () {
        loadConfirmationModal($(this));
        return false;
    })
    $('body').on('click', '.js_load_modal_selection', function () {
        loadModalSelection($(this));
        return false;
    })
    initCloseButton();
    initSubmitButton();
    initDuplicateButton();
    initDeleteButton();
})

$(document).ajaxStart(function () {
    dialogHtml = $('#js_dialog_form').html();
});

function loadDialogElement() {
    var counter = $('#js_dialog_form').length;
    if (counter == 0) {
        var element = "<div id=\"js_dialog_form\"></div>";
        $('body').append(element);
    }
}

function loadModalContent(url, form, width) {
    $.ajax({
        type: 'GET',
        url: url,
        dataType: 'json',
        data: form.serialize(),
        beforeSend: function () {
            $('#js_dialog_form').html(alertLoading());
            $('#js_dialog_form').dialog({
                title: "",
                width: 200,
                modal: true,
            })
            $(".ui-dialog-titlebar").hide();
        },
        success: function (response) {
            if (response.saved) {
                $('#js_dialog_form').html(alertDataSaved() + alertReloading());
                $('#js_dialog_form').dialog({
                    title: response.title,
                    width: width,
                    maxWidth: 900
                })
                setTimeout(function () {
                    location.reload();
                }, 500);
            } else {
                $(".ui-dialog-titlebar").show();
                $('#js_dialog_form').html(response.html);
                $('#js_dialog_form').dialog({
                    title: response.title,
                    width: width,
                    maxWidth: 900
                })
                setDeleteButtons();
                $('.js_datepicker').datepicker({
                    dateFormat: "dd/mm/yy",
                    firstDay: 1
                });
            }
        }
    });
}

function loadModal(element) {
    loadDialogElement();
    var url = element.data('url');
    var size = element.data('size');
    var width = 800;
    if (size === 'large') {
        width = '90%';
    }
    if (size === 'large') {
        width = '90%';
    }
    var form = $('#js_table_form_modal:first');
    loadModalContent(url, form, width);
}

function loadModalDownload(element) {
    loadDialogElement();
    var url = element.data('url');
    var filename = element.data('download-filename');
    var form = $('#js_table_form_modal:first');
    $.ajax({
        type: 'GET',
        url: url,
        data: form.serialize(),
        xhrFields: {
            responseType: 'blob'
        },
        beforeSend: function () {
            $('#js_dialog_form').html(alertLoading());
            $('#js_dialog_form').dialog({
                title: "",
                width: 200,
                modal: true,
            })
            $(".ui-dialog-titlebar").hide();
        },
        success: function (data) {
            var a = document.createElement('a');
            var url = window.URL.createObjectURL(data);
            console.log(data);
            a.href = url;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(url);
        },
        complete: function () {
            $('#js_dialog_form').dialog("close");
        }
    });
}

function loadConfirmationModal(element) {
    var url = element.data('url');
    var data = {
        reference: element.data('reference'),
    };
    var size = element.data('size');
    var width = 800;
    if (size === 'large') {
        width = '90%';
    }
    $.ajax({
        type: 'GET',
        url: url,
        data: data,
        dataType: 'json',
        beforeSend: function () {
            $('#js_dialog_form').html(alertLoading());
            $('#js_dialog_form').dialog({
                title: "",
                width: 200,
                modal: true,
            })
            $(".ui-dialog-titlebar").hide();
        },
        success: function (response) {
            $(".ui-dialog-titlebar").show();
            $('#js_dialog_form').html(response.html);
            $('#js_dialog_form').dialog({
                title: response.title,
                width: width,
            })
            setDeleteButtons();
        }
    });
}

function loadModalSelection(element) {
    var url = element.data('url');
    $.ajax({
        type: 'POST',
        url: url,
        data: $('.js_load_modal_selection_item:checked').serialize(),
        dataType: 'json',
        beforeSend: function () {
            $('#js_dialog_form').html(alertLoading());
            $('#js_dialog_form').dialog({
                title: "",
                width: 200,
                modal: true,
            })
            $(".ui-dialog-titlebar").hide();
        },
        success: function (response) {
            $(".ui-dialog-titlebar").show();
            $('#js_dialog_form').html(response.html);
            $('#js_dialog_form').dialog({
                title: response.title,
                width: 800,
            })
            setDeleteButtons();
            $('.js_datepicker').datepicker({
                dateFormat: "dd/mm/yy",
                firstDay: 1
            });
        }
    });
}

function initSubmitButton() {
    $('body').on('click', 'input[name=submit_modal]', function (e) {
        var form = $(this).closest('form');
        var type = 'DELETE';
        if ($(this).data('type')) {
            type = $(this).data('type');
        }
        var url = $(this).data('url');
        var redirect = $(this).data('redirect');
        var html = $('#js_dialog_form').html();
        var data = form.serialize();
        if ($("#message-editor").length == 1) {
            var formdata = form.serializeArray();
            var data = {};
            $.each(formdata, function (i, field) {
                if (field.value.trim() != "") {
                    if (data[field.name] != undefined) {
                        var val = data[field.name];
                        if (!Array.isArray(val)) {
                            arr = [val];
                        }
                        arr.push(field.value.trim());
                        data[field.name] = arr;
                    } else {
                        data[field.name] = field.value;
                    }
                }
            });
            var ckeditordata = CKEDITOR.instances['message-editor'].getData();
            data['ckeditordata'] = ckeditordata;
            console.log(data);
        }
        e.preventDefault();
        $.ajax({
            type: type,
            url: url,
            data: data,
            dataType: 'json',
            beforeSend: function () {
                $('#js_dialog_form .js_notifier').html(alertProcessing());
            },
            success: function (response) {
                if (response.saved) {
                    $('#js_dialog_form').html(alertDataSaved() + alertReloading());
                    if (typeof redirect !== 'undefined') {
                        loadModalContent(redirect, form, '90%');
                    } else {
                        setTimeout(function () {
                            location.reload();
                        }, 500);
                    }
                } else if (response.mailed) {
                    $('#js_dialog_form').html(alertDataMailed() + alertReloading());
                    if (typeof redirect !== 'undefined') {
                        loadModalContent(redirect, form, '90%');
                    } else {
                        setTimeout(function () {
                            location.reload();
                        }, 500);
                    }
                } else if (response.html) {
                    $(".ui-dialog-titlebar").show();
                    $('#js_dialog_form').html(response.html);
                    $('#js_dialog_form').dialog({
                        title: response.title
                    })
                    setDeleteButtons();
                } else {
                    $('#js_dialog_form .js_notifier').html(response.error);
                }
            }
        });
    })
}

function initCloseButton() {
    $('body').on('click', '.js_close_modal', function (e) {
        e.preventDefault();
        $('#js_dialog_form').html('');
        $('#js_dialog_form').dialog("close");
    })
}

function initDuplicateButton() {
    $('body').on('click', '.js_duplicate_input', function (e) {
        e.preventDefault();
        var classToDuplicate = $(this).data('duplicateClass');
        var wrapperToAppend = $(this).data('duplicateWrapperId');
        var clonedElement = $('#js_dialog_form .' + classToDuplicate).first().clone();
        clonedElement.find('input').val('');
        clonedElement.find('textarea').val('');
        clonedElement.find('select').val('');
        $('#js_dialog_form #' + wrapperToAppend).append(clonedElement);
        setDeleteButtons();
    })
}

function initDeleteButton() {
    $('body').on('click', '.js_delete_data', function (e) {
        e.preventDefault();
        $(this).closest('.js_input_data').remove();
        setDeleteButtons();
    })
}

function setDeleteButtons() {
    var countButtons = $('#js_dialog_form .js_delete_data').length;
    if (countButtons > 1) {
        $('#js_dialog_form .js_delete_data').show();
    } else {
        $('#js_dialog_form .js_delete_data').hide();
    }
}
