function toggleVolunteervaluesFields() {
    var isVolunteer = $('input[name=\'is_volunteer\']:checked').val()
    if (isVolunteer == 1) {
        $('#js-volunteervalues-group').slideDown();
    } else {
        $('#js-volunteervalues-group').slideUp();
        $('#js-volunteervalues-group select').each(function () {
            $(this).prop("selectedIndex", 0);
        });
        $('#js-volunteervalues-group input.js-datepicker').each(function () {
            $(this).val('');
        });
    }
}


$(function () {
    $(".js-datepicker").datepicker({
        dateFormat: "dd/mm/yy",
        changeMonth: true,
        changeYear: true,
        yearRange: "-110:+10"
    });

    toggleVolunteervaluesFields();
    $('input[name=\'is_volunteer\']').on('click', function () {
        toggleVolunteervaluesFields();
    })

});
