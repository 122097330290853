$(function () {
    $("body").on("keyup", "#js-search", function() {
        var url = $(this).data('url');
        var searchvalue = $(this).val();
        $.ajax({
            type: 'POST',
            url: url,
            data: {"searchvalue": searchvalue},
            dataType: 'json',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            beforeSend: function() {
                $('#js_dialog_form').html(alertLoading());
                $('#js_dialog_form').dialog({
                    title: "",
                    width: 200,
                    modal: true,
                })
                $(".ui-dialog-titlebar").hide();
            },
            success: function (response) {
                $('#js-table').html(response.html);
            },
            complete: function () {
                $('#js_dialog_form').dialog("close");
            }
        });
    })
});
