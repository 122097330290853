$(function () {
    $('.js-select2').select2({
        tags: true,
        width: 'resolve'
    });

    $("div.form-dropzone").dropzone({
        url: "/" + $('div.form-dropzone').data('url') + "/" + $('input[name=record_id]').val() + "/files",
        acceptedFiles: ".jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .csv, .pdf",
        uploadMultiple: true,
        maxFilesize: 4,
        dictDefaultMessage: "Drop bestanden hier of klik om te uploaden.",
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        successmultiple: function(_files, response) {
            response = JSON.parse(response);
            $('ul#js-contact-documents').append(response.html);
        }
    });
});
