$(function () {

    $("#js_hide_new_password").hide();
    $("#new_password").hide();

    $("body").on("click", "#js_set_new_password", function(e) {
        e.preventDefault();
        $("#js_hide_new_password").show();
        $("#new_password").show().attr('disabled', false);
        $(this).hide();
    })

    $("body").on("click", "#js_hide_new_password", function(e) {
        e.preventDefault();
        $("#js_set_new_password").show();
        $("#new_password").hide().attr('disabled', true);
        $(this).hide();
    })

});
